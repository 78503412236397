/* eslint-disable no-console */
import React from 'react';
import CardTile from './CardTile';
import {
    requestCreatePage,
    requestCopyPage,
    requestDeletePage,
    requestFocusPage,
    requestSavePage,
    requestSortPages,
    requestPageList,
    requestPopupList,
    requestPopupSave,
    requestSelectedPopup,
    requestPopupDelete,
} from '../../requests/pages-requests';
import { Panel, PanelGray, PanelHeader, PanelSection } from '@/unlayer-tools/components/pages-panel-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import debounce from 'lodash/debounce';
import PageSettings from '@/unlayer-tools/panels/pages-panel/PageSettings';
import { KeapCustomLinkTypes, refreshLinkType } from '@/unlayer-tools/custom-link-types';
import { intl } from '@/shared/intl';
import { Oval } from 'react-loader-spinner';
import style from './PageSettings.scss';
import WarningAlertMessage from '../../unlayer-form-tool/renderer/formFields/WarningAlertMessage';
import ReactSelect from 'react-select';

export function createPagesPanelSettings(allowEditPages, maxPageCount, popupEnabled) {
    const PagesPanel = buildPagesPanelComponent(allowEditPages, maxPageCount, popupEnabled);

    const PagesPanelSettings = {
        name: 'funnel_pages',
        label: intl.get('pagesPanel.panelName', { defaultMessage: 'Pages' }),
        icon: 'fa-layer-group',
        // todo: Add the correct svg logo
        // icon: 'https://storage.googleapis.com/is-unlayer-tools.appspot.com/builds/layers.svg',
        supportedDisplayModes: ['web', 'popup'],
        renderer: {
            Panel: PagesPanel,
        },
    };

    return PagesPanelSettings;
}

export function buildPagesPanelComponent(allowEditPages, maxPageCount, popupEnabled) {
    class PagesPanel extends React.Component {
        constructor(props, context) {
            super(props, context);
            this.state = {
                pages: [],
                isEditing: false,
                currentName: null,
                currentlySaving: {},
                allowEditPages: allowEditPages,
                maxPageCount: maxPageCount,
                isLoading: true,
                popupList: [],
                selectedPopup: {},
            };

            this.resortPagesDebounced = debounce((newPages) => {
                return requestSortPages(newPages);
            }, 1000);
        }

        async componentDidMount() {
            const responseData = await requestPageList();

            if (!responseData?.selectedPage) {
                this.setState({ pages: responseData?.pageData, isLoading: false, selectedPage: 0 });
            } else {
                const index = responseData?.pageData.findIndex((page) => page.id === responseData?.selectedPage);

                this.setState({ pages: responseData?.pageData, isLoading: false, selectedPage: index });

                await requestFocusPage(responseData?.selectedPage);
            }

            await Promise.all([this.loadSelectedPopup(), this.loadPopupList()]);
        }

        movePage(dragIndex, hoverIndex) {
            const { pages } = this.state;
            let newPages = pages.slice(0);
            let temp = newPages[dragIndex];

            newPages[dragIndex] = newPages[hoverIndex];
            newPages[hoverIndex] = temp;
            this.setState((state) => ({
                ...state,
                pages: newPages,
            }));

            this.resortPagesDebounced(newPages.map(({ id }) => id));

            return true;
        }

        setPageStage(pageId, updates) {
            this.setState(({ pages, ...other }) => ({
                ...other,
                pages: pages.map((page) => page.id === pageId ? { ...page, ...updates } : page),
            }));
        }

        async editPageSettings(pageId, currentName) {
            // noinspection ES6MissingAwait
            this.selectPage(pageId, this.state.selectedPage);

            this.setState((state) => ({
                ...state,
                isEditing: true,
                currentName,
            }));
        }

        async addPage() {
            let savedPage;

            this.setState({ isCreating: true });

            try {
                if (this.state.pages.length < this.state.maxPageCount) {
                    savedPage = await requestCreatePage();

                    if (savedPage) {
                        this.setState({
                            pages: [...this.state.pages, savedPage],
                            selectedPage: this.state.pages.length,
                        });
                        await Promise.all[requestFocusPage(savedPage.id), refreshLinkType(KeapCustomLinkTypes.marketingPage)];
                    }
                } else {
                    this.setState({ errors: [...this.state.errors ?? [], 'max_page_count'] });
                    throw new Error(`Cannot create more than ${this.state.maxPageCount} pages.`);
                }
            } catch (e) {
                console.warn(`Error ${e}`);
            } finally {
                this.setState({ isCreating: false });
            }
        }

        async copyPage(existingPageId) {
            const { pages } = this.state;
            let copiedPage;

            this.setState({ isCreating: true });

            try {
                if (this.state.pages.length < this.state.maxPageCount) {
                    await requestFocusPage(existingPageId);

                    copiedPage = await requestCopyPage(existingPageId);

                    if (copiedPage) {
                        this.setState({
                            pages: [...pages, copiedPage],
                            selectedPage: pages.length,
                            isCreating: false,
                        });
                        await Promise.all([requestFocusPage(copiedPage.id), refreshLinkType(KeapCustomLinkTypes.marketingPage)]);
                    }
                } else {
                    this.setState({ errors: [...this.state.errors ?? [], 'max_page_count'] });
                    throw new Error(`Cannot create more than ${this.state.maxPageCount} pages.`);
                }
            } catch (e) {
                console.warn(`Error ${e}`);
            }
        }

        async deletePage(pageId) {
            const { pages } = this.state;
            const deleteConfirmation = await requestDeletePage(pageId);

            if (deleteConfirmation) {
                this.setState({
                    ...this.state,
                    pages: pages.filter((page) => page.id !== pageId),
                    selectedPage: 0,
                });
            }
            await Promise.all[requestFocusPage(pages[0]?.id), refreshLinkType(KeapCustomLinkTypes.marketingPage)];
        }

        selectPage(selectedPage, pageIndex) {
            if (selectedPage !== pageIndex) {
                this.setState((state) => ({
                    ...state,
                    selectedPage: pageIndex,
                }));

                const page = this.state.pages[pageIndex];

                return requestFocusPage(page.id);
            }
        }

        async updatePage(currentPageId, { pageName, seoDescription }) {

            this.setState((state) => ({
                ...state,
                pages: state.pages.map((page) => {
                    if (page.id === currentPageId) {
                        return {
                            ...page,
                            name: pageName,
                            seoDescription,
                        };
                    } else {
                        return page;
                    }
                }),
                currentlySaving: {
                    ...state.currentlySaving,
                    [currentPageId]: true,
                },
                isEditing: false,
            }));

            try {
                await requestSavePage(currentPageId, { pageName, seoDescription });
                await refreshLinkType(KeapCustomLinkTypes.marketingPage);
            } finally {
                this.setState((state) => ({
                    ...state,
                    currentlySaving: {
                        ...state.currentlySaving,
                        [currentPageId]: false,
                    },
                }));
            }
        }

        get currentPage() {
            const { pages, selectedPage } = this.state;

            return pages[selectedPage] ?? 1;
        }

        loadSelectedPopup = async () =>  {
            try {
                const data = await requestSelectedPopup();
                const selectedPopup = data?.map(({ id, label }) => ({ value: id, label }));

                this.setState({ selectedPopup: selectedPopup[0] || {} });

            } catch (error) {
                console.error(error);
            }
        }

         loadPopupList = async () =>  {
             try {
                 const popupList = await requestPopupList();
                 const newPopupList = popupList?.map((popup) => ({ label: popup.title, value: popup.id }));

                 this.setState({ popupList: newPopupList });}
             catch (error) {
                 console.error(error);
             }
         }

         selectPopup = async (value) => {
             this.setState({ selectedPopup: value });
             await requestPopupSave(value);
         };

        handlePopupChange = async (value) => {
            const { selectedPopup } = this.state;

            if (!selectedPopup.value) {
                this.selectPopup(value);
            } else {
                this.handleDeletePopup(selectedPopup.value);
                this.selectPopup(value);
            }
        };

        async handleDeletePopup(popupId) {
            try {
                const data = await requestPopupDelete(popupId);

                if (data && popupId === this.state.selectedPopup.value) {
                    this.setState({ selectedPopup: {} });
                }
            }
            catch (error) {
                console.error(error);
            }
        }

        render() {
            const { isEditing = false, selectedPage, isLoading, pages, isCreating = false, selectedPopup, popupList,
            } = this.state;

            const { currentPage } = this;

            if (isEditing) {
                return (
                    <PanelGray>
                        <PageSettings page={currentPage} editMode={isEditing} onClose={({ pageName, seoDescription }) => {
                            return this.updatePage(currentPage.id, { pageName, seoDescription });
                        }} />
                    </PanelGray>
                );
            } else {
                return (
                    <Panel>
                        <PanelSection className={style.PagesPanelList}>
                            {
                                <PanelHeader title={intl.get('pagesPanel.visiblePageHeader', { defaultMessage: 'Landing Pages' })} trailing={
                                    <React.Fragment>
                                        {pages.length > (Math.floor(this.state.maxPageCount / 2)) &&
                                                (pages.length === this.state.maxPageCount
                                                    ? <span style={{ color: '#CCCCCC' }}>{`${pages.length}/${this.state.maxPageCount} `}</span>
                                                    : <span>{`${pages.length}/${this.state.maxPageCount} `}</span>)
                                        }
                                        {isCreating || pages.length === this.state.maxPageCount
                                            ? <FontAwesomeIcon style={{ cursor: 'pointer', color: '#CCCCCC' }} icon={faPlus} />
                                            : <FontAwesomeIcon data-testid='create-page' style={{ cursor: 'pointer' }} icon={faPlus}
                                                onClick={() => this.addPage()} />}
                                    </React.Fragment>
                                } />
                            }
                            <div style={{ padding: '8px', width: '100%', overflowY: 'scroll', height: '450px' }}>
                                <React.Fragment>
                                    {!isLoading ? pages.map((page, index) =>
                                        <CardTile
                                            data-testid={`page-tile-${page.id}`}
                                            key={page.id}
                                            index={index}
                                            canDelete={this.state.pages.length > 1}
                                            canDrag={true}
                                            canEdit={true}
                                            canCopy={this.state.pages.length < this.state.maxPageCount}
                                            onEdit={() => this.editPageSettings(page.id, page.name)}
                                            movePage={this.movePage.bind(this)}
                                            onDelete={() => this.deletePage(page.id)}
                                            onCopy={() => this.copyPage(page.id)}
                                            allowEditPages={this.state.allowEditPages}
                                            onSelect={() => this.selectPage(selectedPage, index)}
                                            isFocused={index === selectedPage}
                                            isSaving={this.state.currentlySaving[page.id]}
                                            pageName={page.name}
                                            pageId={page.id} />) : (<Oval
                                        visible={true}
                                        height="30"
                                        width="30"
                                        color="#000000"
                                        secondaryColor='gray'
                                        ariaLabel="oval-loading"
                                        radius="1"
                                        wrapperStyle={{ display: 'block', width: '100%', textAlign: 'center' }}
                                        wrapperClass='loaderStyle'
                                    />)}


                                    {isCreating && <>
                                        <div>
                                            <Oval
                                                visible={true}
                                                height="30"
                                                width="30"
                                                color="#000000"
                                                secondaryColor='gray'
                                                ariaLabel="oval-loading"
                                                radius="1"
                                                wrapperStyle={{ display: 'block', width: '100%', textAlign: 'center' }}
                                                wrapperClass='loaderStyle'
                                            />
                                        </div>
                                    </>}
                                </React.Fragment>
                            </div>

                        </PanelSection>

                        {
                            popupEnabled && <PanelSection className='popup-list'>
                                <PanelHeader title="Popup" trailing={
                                    <React.Fragment>
                                        {`${Object.keys(selectedPopup).length ? '1' : '0' }/1`}
                                    </React.Fragment>
                                }/>
                                <div style={{ padding: '8px', width: '100%' }}>
                                    { selectedPopup && selectedPopup.value ? (
                                        <CardTile
                                            key={selectedPopup.value}
                                            canDrag={false}
                                            canDelete={true}
                                            canCopy={false}
                                            canEdit={false}
                                            onDelete={() => this.handleDeletePopup(selectedPopup.value)}
                                            onEdit={() => this.handleEditPopup(selectedPopup.value)}
                                            pageName={selectedPopup.label}
                                        />
                                    ) : (
                                        ''
                                    )}

                                    <ReactSelect
                                        value={selectedPopup}
                                        options={popupList}
                                        onChange={this.handlePopupChange}
                                        placeholder="Select a popup"
                                    />
                                    <div className='popup-alert' style={{ marginTop: '8px' }}>
                                        <WarningAlertMessage
                                            alertMessage={intl.get('unalyer.tool.form.virtual.field.alert.message',
                                                { defaultMessage: 'You can add one popup page on top of your landing page to increase conversions and lead generation. If you remove it, the original popup page you created will not be deleted.' })}
                                        />
                                    </div>
                                </div>
                            </PanelSection>
                        }
                    </Panel>
                );
            }
        }
    }

    return PagesPanel;
}
